<template>
  <div class="app-admin-wrap layout-sidebar-large clearfix">
    <top-nav />
    <multiple-login-control v-if="isModeProduction" />
    <main class="main-content-wrap bg-main pb-4">
      <transition name="page" mode="out-in">
        <router-view />
      </transition>
    </main>
  </div>
</template>

<script>
import MultipleLoginControl from '@/components/util/MultipleLoginControl.vue';

import TopNav from './TopNav.vue';

export default {
  components: {
    MultipleLoginControl,
    TopNav,
  },
  computed: {
    isModeProduction() {
      return process.env.NODE_ENV === 'production';
    },
  },
  data() {
    return {};
  },
  methods: {

  },
};
</script>
<style>
/* sobrescritura de clases de bootstrap */
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
/* end */

/* clases personalizadas para b-modal */
.modal-custom-xl {
  max-width: 1140px;
}
/* como usar => <b-modal ... size='custom-xl'> */
.modal-custom-lg-max {
  max-width: 990px;
}
/* como usar => <b-modal ... size='custom-lg-max'> */
/* end */

/* clases personalizadas del proyecto */
.bg-main{
  background:#fff !important;
}
.page-item.active .page-link {
  z-index: 1;
  color: black;
  background-color: #2efe9a !important;
  border-color: #2efe9a !important;
}
.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.custom-select:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #66afe9;
}
.bg-custom-green{
  color: black;
  background-color: #2efe9a;
}
.custom-border-top{
  border-top: 1px solid #2efe9a !important;
}
.th-custom-color{
  background-color: #2efe9a;
}
/* b-Table */
.table th, .table td {
  padding: 0.75rem;
  vertical-align: top;
  border: none;
}
.table thead th {
  vertical-align: bottom;
  border: none;
}
.table.b-table > thead > tr > th[aria-sort=none]::before, .table.b-table > tfoot > tr > th[aria-sort=none]::before {
  float: right;
  content: "\2195";
}
.table.b-table > thead > tr > [aria-sort=none], .table.b-table > tfoot > tr > [aria-sort=none] {
  background-image: none;
}
.table.b-table > thead > tr > th[aria-sort][aria-sort="descending"]::before, .table.b-table > tfoot > tr > th[aria-sort][aria-sort="descending"]::before {
  float: right;
  content: "\2193";
}
.table.b-table > thead > tr > [aria-sort=descending], .table.b-table > tfoot > tr > [aria-sort=descending] {
  background-image: none;
}
.table.b-table > thead > tr > th[aria-sort][aria-sort="ascending"]::before, .table.b-table > tfoot > tr > th[aria-sort][aria-sort="ascending"]::before {
  float: right;
  content: "\2191";
}
.table.b-table > thead > tr > [aria-sort=ascending], .table.b-table > tfoot > tr > [aria-sort=ascending] {
  background-image: none;
}
/* DropDown */
.dropdown-item:active
{
  background-color: #2efe9a;
  color:black;
}
/* Loaders */
.screen-center {
  transform: translateY(27vh);
}
</style>
